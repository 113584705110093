import React, { useState } from 'react';
import * as Yup from 'yup';
import { Grid, Box, Card, CardContent, Stack } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import Typography from '@mui/material/Typography';
import { fetchAdminDomainLookup, fetchAdminIndustryLookup } from 'src/modules/auth/api/authApis';
import { isArray } from 'lodash';
import { useSelector } from 'react-redux';

function RoleplayView({ tenant, tenantSettings, updateSettings }) {
  const [loading, setLoading] = useState(false);
  const globalConfig = useSelector((state) => state.app.globals);

  const handleSubmit = async (values, form) => {
    try {
      setLoading(true);
      const data = { roleplaySettings: { ...values } };
      updateSettings(data, tenantSettings.id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const {
    domain = '',
    industry = '',
    minimumRoleplayScore = 0,
    isCustomise = false,
  } = tenantSettings?.roleplaySettings ?? {};

  return (
    <React.Fragment>
      <Spacer y={4} />
      <Card>
        <CardContent>
          <Form
            initialValues={{
              domain: !!tenantSettings?.roleplaySettings
                ? isArray(domain)
                  ? domain[0]
                  : domain
                : {},
              industry: !!tenantSettings?.roleplaySettings
                ? isArray(industry)
                  ? industry[0]
                  : industry
                : {},
              minimumRoleplayScore:
                minimumRoleplayScore || globalConfig?.minimumRoleplayScore || 4.5,
              isCustomise: isCustomise || false,
            }}
            validationSchema={Yup.object().shape({
              domain: Yup.object().test('domain', 'Please choose a domain.', function (item) {
                return !!item?.value;
              }),
              industry: Yup.object().test('industry', 'Please choose a industry.', function (item) {
                return !!item?.value;
              }),
            })}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validateOnBlur={true}
            validateOnChange={false}
          >
            {(props) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.submitForm();
                    return false;
                  }}
                  style={{ height: '100%' }}
                  noValidate
                >
                  <Box display="flex" flexDirection="column" height="100%">
                    <Box>
                      <Typography variant="h4" gutterBottom>
                        AI Learning Settings
                      </Typography>
                      <Spacer x={1} y={1} />
                      <Typography variant="body2" color="textSecondary">
                        Choose your domain and industry specialism to ensure every roleplay is
                        personalised to your exact needs.
                      </Typography>
                    </Box>
                    <Spacer x={2} y={2} />
                    <Grid container spacing={2}>
                      {/* <Grid item xs={6}>
                        <Form.Field.AutoComplete
                          name="domain"
                          options={[]}
                          fullWidth={true}
                          variant="outlined"
                          remoteMethod={(val) => {
                            return fetchAdminDomainLookup(val);
                          }}
                          optLabel="label"
                          optValue="value"
                          label="My domain is"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Form.Field.AutoComplete
                          name="industry"
                          options={[]}
                          fullWidth={true}
                          variant="outlined"
                          label="My industry expertise is"
                          remoteMethod={(val) => {
                            return fetchAdminIndustryLookup(val);
                          }}
                          optLabel="label"
                          optValue="value"
                        />
                      </Grid> */}
                      <Grid item xs={6}>
                        <Stack>
                          <Form.Field.Number
                            name="minimumRoleplayScore"
                            floatNumber
                            fullWidth={true}
                            variant="outlined"
                            label="Minimum Score"
                          />
                          <Typography>
                            The minimum score required to complete the roleplay. example: 3.5
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={6} display={'flex'} justifyContent={'center'} spacing={2}>
                        <Stack direction="row">
                          <Form.Field.Switch
                            fullWidth
                            rows={4}
                            name="isCustomise"
                            variant="outlined"
                            label={'Allow users to customise AI Coach Objections'}
                          />
                        </Stack>
                      </Grid>
                    </Grid>

                    <Box mt={2} display="flex">
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        loading={loading}
                        disabled={loading}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </form>
              );
            }}
          </Form>
        </CardContent>
      </Card>
      {/* <Spacer y={5} />
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="column" height="100%">
            <Box>
              <Typography variant="h4" gutterBottom>
                Player auto-play
              </Typography>
              <Spacer y={1} />
              <Typography variant="body2" color="textSecondary"></Typography>
            </Box>
            <Spacer y={1} />
            <Grid container spacing={2} padding={"0 14px"}>
              <Grid item xs={6}>
                <Form
                  initialValues={{
                    playerAutoPlay: tenantSettings?.roleplaySettings?.playerAutoPlay
                  }}
                  enableReinitialize={true}
                >
                  <Form.Field.Switch
                    fullWidth
                    rows={4}
                    onChange={(value) => {
                      const data = { roleplaySettings: { ...(tenantSettings?.roleplaySettings || {}), playerAutoPlay: value } };
                      updateSettings(data, tenantSettings.id);
                    }}
                    name="playerAutoPlay"
                    variant="outlined"
                    label={"Player auto-play"}
                  />
                </Form>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card> */}
    </React.Fragment>
  );
}

export default RoleplayView;
